var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrap patients" },
    [
      _vm.patients.length
        ? _vm._l(_vm.patients, function (item, index) {
            return _c(
              "div",
              { key: index, staticClass: "patient flex f-jc-sb f-ai-c" },
              [
                _c("div", { staticClass: "p-l" }, [
                  _c("div", { staticClass: "p-i flex" }, [
                    _c("i", { staticClass: "flex f-ai-c" }, [
                      _vm._v(_vm._s(item.name)),
                    ]),
                    _c("span", { staticClass: "g flex f-ai-c" }, [
                      _vm._v(_vm._s(item.gender === 1 ? "男" : "女")),
                    ]),
                    _c("span", [_vm._v(_vm._s(item.age) + "岁")]),
                    item.isDefault === 2
                      ? _c("a", { staticClass: "default-icon" }, [
                          _vm._v("默认"),
                        ])
                      : _vm._e(),
                  ]),
                  _c("span", { staticClass: "p-m" }, [
                    _vm._v(_vm._s(item.mobilePhone)),
                  ]),
                ]),
                item.isDefault === 1
                  ? _c("div", {
                      staticClass: "p-r",
                      on: {
                        click: function ($event) {
                          return _vm.del(item.id)
                        },
                      },
                    })
                  : _vm._e(),
              ]
            )
          })
        : _c("div", { staticClass: "no-pat flex f-jc-c f-ai-c" }, [
            _c("img", {
              attrs: {
                src: require("../../assets/images/no-data.png"),
                alt: "",
              },
            }),
            _c("span", [_vm._v("暂未添加就诊人~")]),
          ]),
      _vm.patients.length === 5
        ? _c("div", { staticClass: "bottom-tips" }, [
            _c("span", {}, [_vm._v("最多添加5个就诊人")]),
          ])
        : _vm._e(),
      _vm.patients.length < 5
        ? _c("s-button", {
            staticClass: "sub-btn",
            attrs: { btnText: "添加就诊人" },
            on: { "s-click": _vm.clickHandle },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }