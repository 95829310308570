<template>
	<div class="wrap patients">
		<template v-if="patients.length">
			<div class="patient flex f-jc-sb f-ai-c" v-for="(item, index) in patients" :key="index">
				<div class="p-l">
					<div class="p-i flex">
						<i class="flex f-ai-c">{{item.name}}</i>
						<span class="g flex f-ai-c">{{item.gender === 1 ? '男' : '女'}}</span>
						<span>{{item.age}}岁</span>
						<a v-if="item.isDefault === 2" class="default-icon">默认</a>
					</div>
					<span class="p-m">{{item.mobilePhone}}</span>
				</div>
				<div v-if="item.isDefault === 1" class="p-r" @click="del(item.id)"></div>
			</div>
		</template>
		<div v-else class="no-pat flex f-jc-c f-ai-c">
			<img src="../../assets/images/no-data.png" alt="">
			<span>暂未添加就诊人~</span>
		</div>
		<div class="bottom-tips" v-if="patients.length === 5">
			<span class="">最多添加5个就诊人</span>
		</div>
		<s-button v-if="patients.length < 5" class="sub-btn" btnText="添加就诊人" @s-click="clickHandle"></s-button>
	</div>
</template>
<script>
import sButton from '@/views/common/button.vue';
import { patientList, patientDel } from '@/request/api/user';
export default {
	data () {
		return {
			patients: []
		};
	},
	components: { sButton },
	created () {
		this.getPatientList();
	},
	methods: {
		clickHandle () {
			this.$root.replace({ path: '/add-patient' });
		},
		getPatientList () {
			patientList().then(data => {
				console.log('data==========', data);
				if (data && data === 'retry') {
					this.getPatientList();
				} else if (data) {
					this.patients = data;
					this.$store.commit('setPatients', data);
				}
			}).catch(e => {
				this.$root.elMsg(e.message, 'fail');
			});
		},
		del (id) {
			patientDel({ patientId: id }).then(data => {
				if (data && data === 'retry') {
					this.del(id);
				} else if (data && data.ok === 1) {
					this.$root.elMsg('操作成功');
					this.getPatientList();
				}
			}).catch(e => {
				this.$root.elMsg(e.message, 'fail');
			});
		}
	}
};
</script>
<style lang="less" scoped>
	.patients {
		position: relative;
		padding-top: 5px;
		.patient {
			height: 73px;
			background: #FFFFFF;
			box-shadow: 0px 4.5px 18px -4px rgba(16, 31, 46, 0.04);
			border-radius: 4px;
			margin-top: 10px;
			padding: 16px 15px 20px;
			box-sizing: border-box;
			position: relative;
			a.default-icon {
				display: inline-block;
				width: 28px;
				height: 14px;
				border-radius: 2px;
				border: 1px solid #FF6450;
				font-size: 10px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #FF6450;
				text-align: center;
				line-height: 14px;
			}
			.p-l {
				.p-i {
					font-size: 15px;
					i {
						position: relative;
						font-family: PingFangSC-Medium, PingFang SC;
						font-weight: 500;
						color: #232830;
						margin-right: 12px;
						&::before {
							content: ' ';
							display: inline-block;
							position: absolute;
							width: 2px;
							height: 12px;
							background-color: #FF6450;
							left: -15px;
						}
						&::after {
							content: ' ';
							width: 1px;
							height: 6px;
							background-color: #BEBEBE;
							position: absolute;
							right: -6px;
							top: 50%;
							transform: translateY(-50%);
						}
					}
					span {
						font-family: PingFangSC-Regular, PingFang SC;
						font-weight: 400;
						color: #232830;
						position: relative;
						margin-right: 12px;
					}
					.g::after {
						content: ' ';
						width: 1px;
						height: 6px;
						background-color: #BEBEBE;
						position: absolute;
						right: -6px;
						top: 50%;
						transform: translateY(-50%);
					}
				}
				.p-m {
					display: inline-block;
					margin-top: 10px;
					font-size: 12px;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
					color: #8B8B8B;
				}
			}
			.p-r {
				width: 17px;
				height: 17px;
				background: url('../../assets/images/delete-icon.png') no-repeat;
				background-size: contain;
			}
		}
		.no-pat {
			height: 400px;
			flex-direction: column;
			img {
				width: 100px;
				height: 100px;
			}
			span {
				font-size: 14px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #8B8B8B;
			}
		}
	}
	.bottom-tips {
		width: 100%;
		margin-top: 15px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #BEBEBE;
	}
</style>